import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
const products = [
  { name: "Product 1", desc: "A nice thing", price: "$9.99" },
  { name: "Product 2", desc: "Another thing", price: "$3.45" },
  { name: "Product 3", desc: "Something else", price: "$6.51" },
  { name: "Product 4", desc: "Best thing of all", price: "$14.11" },
  { name: "Shipping", desc: "", price: "Free" },
];
const addresses = [
  "1 Material-UI Drive",
  "Reactville",
  "Anytown",
  "99999",
  "USA",
];
const payments = [
  { name: "Card type", detail: "Visa" },
  { name: "Card holder", detail: "Mr John Smith" },
  { name: "Card number", detail: "xxxx-xxxx-xxxx-1234" },
  { name: "Expiry date", detail: "04/2024" },
];

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Confirmacion(props) {
  // console.log(props.values);
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Tus datos para quimioterapia
      </Typography>
      <List disablePadding>
        {props.values.perfilLog == "5" ? (
          <ListItem className={classes.listItem}>
            <ListItemText primary={"Cliente Solicitante"} />
            <Typography variant="body2">
              {props.values.idClienteSol.nombre}
            </Typography>
          </ListItem>
        ) : null}
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Tipo de vehículo"} />
          <Typography variant="body2">{props.values.tipo}</Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Centro de asistencia"} />
          <Typography variant="body2">
            {props.values.direccionHospital.title}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Destino"} />
          <Typography variant="body2">{props.values.destino.title}</Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Fecha de inicio del tratamiento"} />
          <Typography variant="body2">{props.values.fechaMostrar}</Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Primer Paciente"} />
          <Typography variant="body2">{props.values.primerPaciente}</Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Pueblo del primer paciente"} />
          <Typography variant="body2">
            {props.values.puebloPrimerPaciente.title}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Dirección del primer paciente"} />
          <Typography variant="body2">
            {props.values.direccionPrimerPaciente}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Teléfono del primer paciente"} />
          <Typography variant="body2">
            {props.values.telfPrimerPaciente}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Teléfono alternativo del primer paciente"} />
          <Typography variant="body2">
            {props.values.telfPrimerPaciente2
              ? props.values.telfPrimerPaciente2
              : "NO"}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary={"N.A.S.S - CIP del primer paciente"} />
          <Typography variant="body2">{props.values.NASS1}</Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Segundo Paciente"} />
          <Typography variant="body2">
            {props.values.segundoPaciente ? props.values.segundoPaciente : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Pueblo del segundo paciente"} />
          <Typography variant="body2">
            {props.values.puebloSegundoPaciente.title
              ? props.values.puebloSegundoPaciente.title
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Dirección del segundo paciente"} />
          <Typography variant="body2">
            {props.values.direccionSegundoPaciente
              ? props.values.direccionSegundoPaciente
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Teléfono del Segundo paciente"} />
          <Typography variant="body2">
            {props.values.telfSegundoPaciente
              ? props.values.telfSegundoPaciente
              : "NO"}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Teléfono alternativo del Segundo paciente"} />
          <Typography variant="body2">
            {props.values.telfSegundoPaciente2
              ? props.values.telfSegundoPaciente2
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"N.A.S.S - CIP del segundo paciente"} />
          <Typography variant="body2">
            {props.values.NASS2 ? props.values.NASS2 : "NO"}
          </Typography>
        </ListItem>
      </List>
      <div className={classes.buttons}>
        {props.activeStep !== 0 && (
          <Button onClick={props.prevStep} className={classes.button}>
            Regresar
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={props.nextStep}
          className={classes.button}
        >
          Solicitar viaje
        </Button>
      </div>
    </React.Fragment>
  );
}
